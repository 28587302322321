import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

// Styled components
const BoxesContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh', // Take full viewport height
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  background: 'linear-gradient(to right, #2991bb, #1f4e85)', // Added your background gradient
}));

const BoxesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '20px', // Consistent spacing between boxes
  maxWidth: '1200px', // Maximum width to prevent boxes from spreading too wide
  margin: '0 auto',
}));

const AlertBox = styled(Box)(({ theme }) => ({
  width: 300,
  borderRadius: 10,
  backgroundColor: '#ffffff',
  position: 'relative',
  margin: '10px', // Even spacing around boxes
  '@media (max-width: 1024px)': {
    margin: '10px 0',  // Adjust margins for smaller screens
  }
}));

const BoxHeader = styled(Box)(({ variant }) => ({
  height: 150,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  color: '#ffffff',
  position: 'relative',
  backgroundImage: variant === 'success'
    ? 'linear-gradient(to right, #cef4ae, #78e1b4)'
    : variant === 'alert'
      ? 'linear-gradient(to right, #f4e9ae, #d9b85e)'
      : 'linear-gradient(to right, #f4c0a9, #da6161)',
}));

const IconWrapper = styled(Box)(({ variant }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '& .MuiSvgIcon-root': {
    fontSize: 90,
    filter: variant === 'success'
      ? 'drop-shadow(2px 3px 4px #90d9a8)'
      : variant === 'alert'
        ? 'drop-shadow(2px 3px 4px #d9b85e)'
        : 'drop-shadow(1px 2px 3px #da6161)',
  },
}));

const BoxContent = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: '#5d5d5d',
  fontFamily: 'Montserrat, sans-serif',
  textAlign: 'center',
  padding: '12% 5%',
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
}));

const AlertBoxes = () => {
  return (
    <BoxesContainer>
      <BoxesWrapper>
        {/* Success Box */}
        {/* <AlertBox>
          <BoxHeader variant="success">
            <IconWrapper variant="success">
              <CheckIcon />
            </IconWrapper>
          </BoxHeader>
          <BoxContent>
            <Typography variant="h3" sx={{ 
              fontSize: '1.5rem', 
              fontWeight: 'bold',
              textTransform: 'uppercase',
              marginBottom: '10px'
            }}>
              Goal Reached!
            </Typography>
            <Typography>
              You've conquered your weekly goal! Keep up the momentum and make it a habit!
            </Typography>
          </BoxContent>
        </AlertBox> */}

        {/* Alert Box */}






        <AlertBox>
  <BoxHeader variant="alert">
    <IconWrapper variant="alert">
      <WarningIcon />
    </IconWrapper>
  </BoxHeader>
  <BoxContent>
    <Typography variant="h3" sx={{ 
      fontSize: '1.5rem', 
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginBottom: '10px',
      color: '#d9534f'  // adding red color to emphasize,
    }}>
      Payment Pending!
    </Typography>
    <Typography sx={{ marginBottom: '12px' ,
        textAlign: 'left',
        fontSize: '0.9rem',

    }}>
      This website is currently operating with limited features due to pending payment from the website administrator.
    </Typography>
    <Typography sx={{ 
      fontSize: '0.8rem', 
      color: '#666',
      fontStyle: 'italic',
      textAlign: 'left'
    }}>
      We apologize for any inconvenience. Normal service will resume once payment is processed.
    </Typography>
  </BoxContent>
</AlertBox>
        {/* Danger Box */}
        {/* <AlertBox>
          <BoxHeader variant="danger">
            <IconWrapper variant="danger">
              <CloseIcon />
            </IconWrapper>
          </BoxHeader>
          <BoxContent>
            <Typography variant="h3" sx={{ 
              fontSize: '1.5rem', 
              fontWeight: 'bold',
              textTransform: 'uppercase',
              marginBottom: '10px'
            }}>
              Stop!
            </Typography>
            <Typography>
              You're not supposed to be here! This is super secret content! Leave soon!
            </Typography>
          </BoxContent>
        </AlertBox> */}
      </BoxesWrapper>
    </BoxesContainer>
  );
};

export default AlertBoxes;