// export const firebaseConfig = {
//   apiKey: "AIzaSyCxg2DVbTedzhrqtsRzv9elzevO5vECg1Y",
//   authDomain: "srxcoin.firebaseapp.com",
//   projectId: "srxcoin",
//   storageBucket: "srxcoin.appspot.com",
//   messagingSenderId: "834429188604",
//   appId: "1:834429188604:web:6217455d8b68adbbdf1a79",
//   measurementId: "G-GHQ831430D"
// };

export const firebaseConfig = {
  apiKey: "AIzaSyAHm0SX-yUUiLgi-aFh9c-R0Lzs1ZZEF-c",
  authDomain: "srxcoin-6e1c1.firebaseapp.com",
  projectId: "srxcoin-6e1c1",
  storageBucket: "srxcoin-6e1c1.appspot.com",
  messagingSenderId: "912064773095",
  appId: "1:912064773095:web:bd4bc7d239035ac3fe9b42",
  measurementId: "G-TBD5EPT35M"
};