import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase.config";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { Navigate } from "react-router-dom";

export const Firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(Firebase);
export const auth = getAuth(Firebase);
export const storage = getStorage(Firebase);

export const doSignOut = () => {
    signOut(auth).then(()=>{
      <Navigate to={{ pathname: "/" }} />
    }).catch(error=> console.log(error))
}
