import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";


// Soft UI Dashboard React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftAvatar from "../../components/SoftAvatar";

// Soft UI Dashboard React examples
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";


// Soft UI Dashboard React base styles
import breakpoints from "../../assets/theme/base/breakpoints";


import CoinsBro from "../../assets/images/illustrations/Coins-bro.svg";
import curved0 from "../../assets/images/curved-images/curved-6.jpg";
import {UserContext} from "../../context/userContext";
import { useContext } from "react";

function Header() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const { userState, userDispatch } = useContext(UserContext);
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.primary.main, 0.6),
              rgba(gradients.primary.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              src={CoinsBro}
              alt="profile-image"
              variant="rounded"
              size="xl"
              sx={{
                backgroundColor: ({ palette: { primary } }) =>  (primary.hovered),
              }}
              shadow="md"
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {(userState.userData.username && "Welcome " + userState.userData.username ) || "Welcome"}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {userState.userData.phone}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;
