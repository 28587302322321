import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { SoftUIControllerProvider } from './context/UIController';
import { Toaster } from 'react-hot-toast';
import { ToastProvider } from './context/toastContext';
import { UserProvider } from './context/userContext';
import ErrorCard from './components/Cards/ErrorCards';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ToastProvider>
            <UserProvider>
                <SoftUIControllerProvider>
                    {/* <App /> */}
                   <ErrorCard title="Error" description="An error occurred while trying to fetch the data" />
                    <div id="recaptcha-container" ></div>
                    <Toaster position="top-center" />
                </SoftUIControllerProvider>
            </UserProvider>
        </ToastProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
