// import React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
// import Backdrop from "@mui/material/Backdrop";
// import { styled } from "@mui/material/styles";

// const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
//   color: theme.palette.primary.contrastText,
//   zIndex: theme.zIndex.drawer + 1,
// }));

// const LoaderRoot = ({ color, open }) => {
//   return (
//     <>
//       <StyledBackdrop open={open}>
//         <CircularProgress color={color} />
//       </StyledBackdrop>
//     </>
//   );
// };

// export default LoaderRoot;


// dotted loader
// import React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
// import Backdrop from "@mui/material/Backdrop";
// import { styled } from "@mui/material/styles";

// const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
//   color: theme.palette.primary.contrastText,
//   zIndex: theme.zIndex.drawer + 1,
// }));

// const LoaderRoot = ({ open }) => {
//   return (
//     <StyledBackdrop open={open}>
//       <CircularProgress
//         color="inherit"
//         variant="indeterminate"
//         style={{ animation: "pulse 1.5s ease-in-out infinite" }}
//       />
//     </StyledBackdrop>
//   );
// };

// export default LoaderRoot;



// pulse loader:
// import React from "react";
// import CircularProgress from "@mui/material/CircularProgress";
// import Backdrop from "@mui/material/Backdrop";
// import { styled } from "@mui/material/styles";

// const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
//   color: theme.palette.primary.contrastText,
//   zIndex: theme.zIndex.drawer + 1,
// }));

// const LoaderRoot = ({ open }) => {
//   return (
//     <StyledBackdrop open={open}>
//       <CircularProgress
//         color="inherit"
//         variant="indeterminate"
//         value={100}
//         style={{
//           width: "10%",
//           height: "10%",
//           position: "absolute",
//           animation: "pulse 1.5s ease-in-out infinite",
//         }}
//       />
//       <CircularProgress
//         color="inherit"
//         style={{ position: "relative" }}
//       />
//     </StyledBackdrop>
//   );
// };

// export default LoaderRoot;

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { alpha, styled } from "@mui/material/styles";
import linearGradient from "../../assets/theme/functions/linearGradient";

// const StyledBackdrop = styled(Backdrop)(({ theme }) => (
// {
//     color: theme.palette.primary.contrastText,
//     zIndex: theme.zIndex.drawer + 1,
//     sx: {"background-color": "black"},
// }));

// "rgba(0, 0, 0, 0.9)"
const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor:  theme.palette.primary.hovered,
    zIndex: theme.zIndex.drawer +1 ,
  }));

const LoaderRoot = styled(({ color, open, disableShrink, size, sx, thickness, value, variant, ...rest }) => {
  const getSize = () => {
    if (typeof size === "number") {
      return size;
    } else if (typeof size === "string") {
      return size;
    } else {
      return 40; // default size if not specified
    }
  };

  const getVariant = () => {
    switch (variant) {
      case "determinate":
        return variant;
      case "indeterminate":
        return variant;
      default:
        return "indeterminate"; // default variant
    }
  };

  return (
    <StyledBackdrop open= {open} {...rest}>
      <CircularProgress
        color={color}
        disableShrink={disableShrink}
        size={getSize()}
        sx={sx}
        thickness={thickness}
        value={value}
        variant={getVariant()}
      />
    </StyledBackdrop>
  );
})(({ theme }) => ({

}));

export default LoaderRoot;
