/** 
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/



// // Soft UI Dashboard React icons
import TableRowsIcon from '@mui/icons-material/TableRows';
import Policy from '@mui/icons-material/Policy';
import Settings from '@mui/icons-material/Settings';
import Home from '@mui/icons-material/Home';
import Article from '@mui/icons-material/Article';
import Users from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import React, { lazy } from "react";



// Lazy load components
const Dashboard = lazy(() => import("../pages/Dashboard/index"));
const History = lazy(() => import("../pages/History/index"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy/index"));
const Terms = lazy(() => import("../pages/Terms/index"));
const AllUsers = lazy(()=> import("../pages/admin/AllUsers/index"));
const AddUser = lazy(()=> import("../pages/admin/AddUser/index"));
const BankDetails = lazy(()=> import("../pages/BankDetails/index"));

const coreRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Home />,
    component: <Dashboard />,
    noCollapse: true,
    access_type: 'with_auth',
  },
  {
    type: "collapse",
    name: "Records",
    key: "records",
    route: "/records",
    icon: <TableRowsIcon />,
    component: <History />,
    noCollapse: true,
    access_type: 'with_auth',
  },
  {
    type: "collapse",
    name: "Bank Details",
    key: "bank_details",
    route: "/bank_details",
    icon: <AccountBalanceIcon/>,
    component: <BankDetails/>,
    noCollapse: true,
    access_type: 'with_auth',

  },
  {

    type: "collapse",
    name: "Privacy Policy",
    key: "privacy_policy",
    route: "/privacy_policy",
    icon: <Article />,
    component: <PrivacyPolicy />,
    noCollapse: false,
    access_type: 'with_auth',

  },
  {
    type: "collapse",
    name: "Terms & Conditions",
    key: "terms_and_conditions",
    route: "/terms_and_conditions",
    icon: <Policy/>,
    component: <Terms />,
    noCollapse: true,
    access_type: 'with_auth',

  },


  { type: "title", title: "Admin Pages", key: "admin-pages", access_type: 'with_admin_auth'  },
  {
    type: "collapse",
    name: "Update Coin Info",
    key: "update_coin_info",
    route: "/update_coin_info",
    icon: <Settings/>,
    component: <AllUsers />,
    noCollapse: true,
    access_type: 'with_admin_auth',

  },
  {
    type: "collapse",
    name: "Add Users",
    key: "add_users",
    route: "/add_users",
    icon: <Users/>,
    component: <AddUser/>,
    noCollapse: true,
    access_type: 'with_admin_auth',

  },

];
const routes = [...coreRoutes]
export default routes;
