import React from "react";
import PropTypes from "prop-types";
import LoaderRoot from "./LoaderRoot";



const Loader = React.forwardRef(
  ({ color="primary", open=true,disableShrink=false,size=52,thickness=5.6,value=0,variant="indeterminate", ...rest }, ref) => {
    return (
      <LoaderRoot
        {...rest}
        ref={ref}
        color={color}
        open={open}
      />
    );
  }
);


  
  Loader.propTypes = {
    color: PropTypes.oneOfType([
      PropTypes.oneOf([
        "inherit",
        "primary",
        "secondary",
        "error",
        "info",
        "success",
        "warning",
      ]),
      PropTypes.string,
    ]),
    disableShrink: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
      ),
      PropTypes.func,
      PropTypes.object,
      PropTypes.bool,
    ]),
    open: PropTypes.bool,
    thickness: PropTypes.number,
    value: PropTypes.number,
    variant: PropTypes.oneOf(["determinate", "indeterminate"]),
  };
  

export default Loader;
